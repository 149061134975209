<template>
  <div class="order-details">
    <div class="d-container" v-loading="loading">
      <div class="d-info">
        <div class="i-content">
          <div class="i-name i-item">
            <div class="i-title">收货人信息</div>
            <div class="i-list">
              <div class="c-name">
                <div>收货人：</div>
                <div class="n-middle">地址：</div>
                <div>手机号码：</div>
              </div>
              <div class="c-details">
                <div>{{ data.accept_name }}</div>
                <div class="d-middle">
                  <span v-for="(addr, key) in data.merger_code_name" :key="key"
                    >{{ addr }}/</span
                  >{{ data.address }}
                </div>
                <div>{{ data.mobile }}</div>
              </div>
            </div>
          </div>
          <div class="i-payment i-item" v-if="isShowAddress == 1">
            <div class="i-title">付款信息</div>
            <div class="i-list">
              <div class="c-name">
                <div>付款方式：</div>
                <div class="n-middle">付款时间：</div>
              </div>
              <div class="c-details">
                <div>{{ data.pay_type == 1 ? "微信支付" : "支付宝支付" }}</div>
                <div class="d-middle">{{ data.pay_time }}</div>
              </div>
            </div>
          </div>
          <div class="i-address i-item" v-if="isShowAddress == 1">
            <div class="i-title">发货信息</div>
            <div class="i-list">
              <div class="c-name">
                <div>发货状态:</div>
                <div class="n-middle" v-if="data.distribution_status == 1">
                  发货时间:
                </div>
                <div class="n-middle" v-else>预计发货时间:</div>
                <div
                  class="n-middle"
                  v-if="
                    data.distribution_status == 1 && data.delivery_status == 3
                  "
                >
                  收货时间:
                </div>
              </div>
              <div class="c-details">
                <div>
                  {{
                    data.distribution_status == 0
                      ? "正在出库"
                      : data.delivery_status == 3
                      ? "已签收"
                      : "已发货"
                  }}
                </div>
                <div class="d-middle" v-if="data.distribution_status == 1">
                  {{ data.send_time }}
                </div>
                <div class="d-middle" v-else>
                  {{
                    new Date(new Date().getTime() + 1000 * 60 * 60 * 24)
                      | dateFormat
                  }}
                </div>
                <div
                  class="d-middle"
                  v-if="
                    data.distribution_status == 1 && data.delivery_status == 3
                  "
                >
                  {{ data.delivery_time | dateFormat }}
                </div>
              </div>
            </div>
            <div
              class="i-btn"
              @click="confirmReceipt()"
              v-if="data.distribution_status == 1 && data.delivery_status == 2"
            >
              确认收货
            </div>
          </div>

          <div
            class="i-payment i-item"
            v-if="isShowAddress == 2 || isShowAddress == 3"
          >
            <div class="i-title">费用信息</div>
            <div class="i-list">
              <div class="c-name">
                <div>商品总金额：</div>
                <div class="n-middle">运费：</div>
                <div>应付金额：</div>
              </div>
              <div class="c-details">
                <div>¥{{ data.payable_amount }}</div>
                <div class="d-middle">¥{{ data.real_freight }}</div>
                <div>¥{{ data.real_amount }}</div>
              </div>
            </div>
          </div>
          <div class="i-address i-item" v-if="isShowAddress == 3">
            <div class="i-title">订单信息</div>
            <div class="i-list">
              <div class="c-name">
                <div>订单状态:</div>
                <div class="n-middle">取消时间：</div>
              </div>
              <div class="c-details">
                <div>已取消</div>
                <div class="d-middle">{{ data.cancle_time }}</div>
              </div>
            </div>
          </div>
          <div class="i-waitPay i-item" v-if="isShowAddress == 2">
            <div class="w-order">订单号：{{ data.order_no }}</div>
            <div class="w-txt">等待付款</div>
            <div class="w-btn" @click="ToPay()">付款</div>
          </div>
        </div>
      </div>
      <!--物流信息 -->
      <div class="d-express clearfix" v-if="data.distribution_status == 1">
        <div class="e-goods">
          <div class="g-container">
            <div class="g-img">
              <img :src="data.orderGoods[0].img" />
            </div>
            <ul class="g-txt">
              <li>快递公司：{{ Delivery.shipping_name }}</li>
              <li>&nbsp;</li>
              <li>快递单号：{{ Delivery.invoice_no }}</li>
            </ul>
          </div>
        </div>
        <div class="e-info">
          <!--商品信息 -->
          <el-timeline :reverse="reverse">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :timestamp="activity.AcceptTime"
            >
              {{ activity.AcceptStation }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <!-- 商品信息展示 -->
      <div class="d-store">
        <div
          class="s-name"
          @click="$router.push('/store/home?id=' + data.store_id)"
        >
          {{ data.store_name }}
        </div>
        <div class="goods-list">
          <table>
            <thead class="t-hd">
              <th class="grap"></th>
              <th class="t-goods">商品</th>
              <th>价格</th>
              <th>商品数量</th>
              <th>操作</th>
              <th class="grap"></th>
            </thead>
            <tbody
              class="t-bd"
              v-for="(item, index) in data.orderGoods"
              :key="index"
            >
              <td class="grap"></td>
              <td class="g-name">
                <div class="n-content">
                  <div class="b-img"><img :src="item.img" /></div>
                  <div>
                    <a :href="'/store/goods?id=' + item.goods_id">{{
                      item.goods_name
                    }}</a>
                  </div>
                </div>
              </td>
              <td class="b-item">¥{{ item.goods_price }}</td>
              <td class="b-item">{{ item.goods_num }}</td>
              <td class="b-opt">
                <div class="o-content">
                  <!-- 申请售后 -->
                  <div
                    class="b-service"
                    v-if="
                      data.distribution_status == 1 &&
                      data.delivery_status == 3 &&
                      item.refundment_doc_id == 0
                    "
                    @click="goApplyService(item.id, item.order_id)"
                  >
                    申请售后
                  </div>
                  <!-- 商品评价 -->
                  <div
                    class="b-evaluate"
                    v-if="
                      data.distribution_status == 1 &&
                      data.delivery_status == 3 &&
                      item.is_reply == 0
                    "
                  >
                    <a
                      :href="
                        '/user/evaluation?id=' +
                        item.goods_id +
                        '&oid=' +
                        item.id
                      "
                      >商品评价</a
                    >
                  </div>
                  <!-- 已评价 -->
                  <div
                    class="b-evaluate"
                    v-if="
                      data.distribution_status == 1 &&
                      data.delivery_status == 3 &&
                      item.is_reply == 1
                    "
                  >
                    <a
                      :href="
                        '/user/haveEvaluation?id=' +
                        item.goods_id +
                        '&oid=' +
                        item.id
                      "
                      >查看评价</a
                    >
                  </div>
                  <!-- 再次购买 -->
                  <div class="b-btn">
                    <a
                      :href="'/store/goods?id=' + item.goods_id"
                      target="_blank"
                      ><i class="fa fa-bolt fa-lg"></i>&nbsp;<span
                        >再次购买</span
                      ></a
                    >
                  </div>
                </div>
              </td>
              <td class="grap"></td>
            </tbody>
          </table>
        </div>
        <div class="s-pay">
          <div class="p-title">
            <div>商品总金额:</div>
            <div class="t-middle">运费：</div>
            <div>实付款:</div>
          </div>
          <div class="p-num">
            <div>¥{{ data.payable_amount }}</div>
            <div class="n-middle">¥{{ data.real_freight }}</div>
            <div>
              <strong>¥{{ data.real_amount }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--点击申请售后弹出框 (超出申请退货退款时间) -->
    <el-dialog
      :visible.sync="afterSalesDialog"
      width="30%"
      :before-close="afterSalesClose"
    >
      <span>
        <i class="fa fa-exclamation-circle"></i>
        &nbsp; 亲，您已错过了申请售后的时间段（交易完成15天内）
      </span>
      <div class="d-content">
        <div class="d-text">如果您有换货或维修诉求，建议您和卖家协商;</div>
        <div class="d-text">如需帮助，您可以向<a href="#">在线客服</a>提问</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { OrderInfo, OrderLogistics,ReceivingOrder } from '@/api/order'
  export default {
    name: "orderDetails",
    data() {
      return {
        id: 0,
        data: [],
        reverse: true,
        isShowAddress: 1, // 1 是已支付 2待支付 3取消
        activities: [],
        Delivery: [],
        loading:false,
        afterSalesDialog:false //申请售后弹出框
      }
    },
    methods: {
      async OrderInfo() { // 订单详情
        this.loading = true;
        const { data: res } = await OrderInfo({ id: this.id });
        this.loading = false;
        if (res.message == 'ok') {
          this.data = res.result
          if (this.data.status == 2 && this.data.pay_status == 0) this.isShowAddress = 3;
          if (this.data.status == 1 && this.data.pay_status == 0) this.isShowAddress = 2;
          if (this.data.distribution_status == 1) this.logistics();
        }
      },
      async logistics() { // 物流信息
        this.loading = true;
        const { data: res } = await OrderLogistics({ id: this.id });
        this.loading = false;
        if (res.message == 'ok') {
          this.activities = res.result.Logistics.Traces
          this.Delivery = res.result.Delivery
        }
      },
      ToPay() {
        this.$router.push('/home/payment?id=' + this.data.pay_id);
        return false;
      },
      confirmReceipt() { //确认收货按钮
        this.$confirm("您确定收到货物且质量符合交易双方的约定吗?如果确认无误后,请点击下面的[确定]完成确认收货的操作。提示:请收到货物后执行此操作,否则可能会钱货两空。", '确认收货', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          this.loading = true;
          const { data: res } = await ReceivingOrder({id:this.id});
          this.loading = false;
          if (res.message == 'ok') {
            this.$message({type: 'success',message: '确认收货操作成功!'});
            this.data = res.result
            if (this.data.status == 2 && this.data.pay_status == 0) this.isShowAddress = 3;
            if (this.data.status == 1 && this.data.pay_status == 0) this.isShowAddress = 2;
            if (this.data.distribution_status == 1) this.logistics();
          }else{
            this.$message({type: 'success',message: '确认收货操作失败!'});
          }
        }).catch(() => { this.$message({ type: 'info', message: '已取消操作' }); });
      },
      goApplyService(oid,id){ // 点击申请售后按钮  
        if(this.data.aftersale) this.$router.push('/home/applyService?id='+id+'&oid='+oid)
        else   this.afterSalesDialog=true
      },
      afterSalesClose(){ // 关闭弹出申请售后弹出框
        this.afterSalesDialog=false
      }
  },
   created() {
      this.id = this.$route.query.id;
      if (typeof this.id !== 'number' && isNaN(this.id)) { this.$router.push("/home/error"); return false; }
      this.OrderInfo();
    }}
</script>

<style lang="less" scoped>
.order-details {
  background: #f9f9f9;
  padding: 20px 0px;

  .d-container {
    width: 1200px;
    min-height: 1000px;
    margin: 0 auto;

    .d-info {
      padding: 10px 0px;
      background: #fff;

      .i-content {
        display: flex;

        .i-item {
          width: 400px;
          height: 220px;
          padding: 10px 20px;
          box-sizing: border-box;
        }

        .i-name {
          border-right: 1px solid #ddd;
        }

        .i-title {
          font-size: 14px;
          font-weight: 400;
          color: #333;
        }

        .i-list {
          display: flex;
          margin: 20px 0px;

          .c-details {
            margin-left: 10px;
          }

          .n-middle {
            margin: 10px 0px;
          }

          .d-middle {
            margin: 10px 0px;
          }
        }

        .i-btn {
          width: 80px;
          height: 30px;
          line-height: 30px;
          background: red;
          color: #fff;
          text-align: center;
          cursor: pointer;
          //   margin-top: 20px;
        }

        .i-payment {
          border-right: 1px solid #ddd;
        }

        .i-waitPay {
          display: flex;
          flex-direction: column;
          align-items: center;

          .w-txt {
            font-size: 20px;
            color: #e1251b;
            font-weight: 600;
            margin: 20px 0px;
          }

          .w-order {
            color: #666;
          }

          .w-btn {
            width: 150px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-weight: 600;
            cursor: pointer;
            font-size: 20px;
            border-radius: 5px;
            background: #e1251b;
            color: #fff;
          }
        }
      }
    }

    .d-express {
      background: #fff;
      margin: 20px 0px;
      padding: 10px 0px;
      height: 220px;
      display: flex;

      .e-goods {
        border-right: 1px solid #ddd;

        .g-container {
          width: 400px;
          height: 220px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;

          .g-img {
            width: 100px;
            height: 100px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .g-txt {
            margin-left: 10px;

            .t-middle {
              margin: 10px 0px;
            }
          }
        }
      }

      .e-info {
        width: 800px;
        padding: 20px;
        overflow-y: auto;

        .el-timeline {
          font-size: 12px;
        }
      }
    }

    .d-store {
      margin: 20px 0px;

      .s-name {
        margin-bottom: 10px;
        cursor: pointer;
        color: #d69977;
      }

      .goods-list {
        border: 1px solid #ddd;
        border-bottom: none;

        .grap {
          width: 20px;
        }

        .t-hd {
          height: 30px;
          font-size: 12px;
          font-weight: 400;
          color: #333;
          border-bottom: 1px solid #ddd;
        }

        .t-goods {
          width: 500px;
        }

        .t-bd {
          height: 120px;
          vertical-align: top;
          background: #fff;
          font-size: 12px;
          box-sizing: border-box;
          border-bottom: 1px solid #ddd;

          .g-name {
            height: 120px;
            position: relative;

            .n-content {
              display: flex;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              padding-right: 20px;
            }

            .b-img {
              width: 60px;
              height: 60px;
              margin-right: 8px;

              img {
                width: 60px;
                height: 60px;
              }
            }
          }

          .b-item {
            text-align: center;
            line-height: 100px;
          }

          .b-opt {
            width: 200px;
            height: 120px;

            .o-content {
              height: 120px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              .b-service {
                color: #666;
                cursor: pointer;
                &:hover {
                  color: #e1251b;
                }
              }

              .b-btn {
                width: 80px;
                height: 30px;
                background: #f5f5f5;
                border: 1px solid #ddd;
                line-height: 30px;
                text-align: center;

                i {
                  color: #999;
                }

                &:hover {
                  border: 1px solid #e1251b;
                  color: #e1251b;

                  i {
                    color: #e1251b;
                  }

                  span {
                    color: #e1251b;
                  }
                }
              }
            }
          }
        }
      }

      .s-pay {
        height: 124px;
        background: #fff;
        border: 1px solid #ddd;
        border-top: none;
        color: #666;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0px 50px;

        .p-title {
          text-align: right;

          .t-middle {
            margin: 10px 0px;
          }
        }

        .p-num {
          margin-left: 20px;
          padding-top: 5px;
          text-align: right;

          .n-middle {
            margin: 10px 0px;
          }
        }

        strong {
          font-size: 18px;
          color: #e1251b;
          margin-left: 0px;
        }
      }
    }
  }

  /deep/ .el-dialog__header {
    padding: 0px;
  }

  /deep/ .el-dialog {
    padding: 0px;
    margin-top: 30vh !important;
    span {
      color: #333;
    }
    i {
      color: #0085d7;
    }
    .d-content {
      margin-top: 10px;
      .d-text {
        font-size: 12px;
        color: #333;
        a {
          color: #0085d7;
        }
      }
    }
  }
}
</style>